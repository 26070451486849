<template>
  <div class="merchantList">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="导出" @click="toExport()"></v-button
      ></template>
      <template #searchSlot>
        <v-input label="商家昵称" v-model="searchParam.nickName" />
        <v-input label="商家手机号" v-model="searchParam.mobileNum" />
        <v-select
          clearable
          :options="merchantStatus"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="状态"
        />
        <v-input label="商家名称" v-model="searchParam.title" />
        <v-select
          clearable
          :options="businessTypeops"
          v-model="searchParam.businessType"
          @change="$refs.list.search()"
          label="商户类型"
        />
        <v-select
          clearable
          :options="manageTypeops"
          v-model="searchParam.manageType"
          @change="$refs.list.search()"
          label="经营类型"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          v-if="
            (scope.row.canEdit == undefined || scope.row.canEdit) &&
            scope.row.status == 0
          "
          type="text"
          @click="tohandle(scope.row)"
        />
        <v-button
          text="查看"
          v-if="scope.row.status != 0"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          text="冻结"
          type="text"
          v-if="scope.row.status == 1"
          @click="changeShelfSts(scope.row, 1)"
        />
        <v-button
          text="解冻"
          type="text"
          v-if="scope.row.status == 3"
          @click="changeShelf(scope.row, 2)"
        />
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="info">
        <span v-if="isHave == 1">该商户已有上架商品，是否确定冻结?</span>
        <span v-else>该商户还没有上架商品，是否确定冻结?</span>
      </div>

      <div class="item">
        <v-input
          type="textarea"
          placeholder="请输入冻结原因"
          v-model="form.freezeReason"
          :width="250"
          label="冻结原因"
        ></v-input>
      </div>
    </v-dialog>
    <v-dialog
      title="审核"
      v-model="isdialog1"
      @cancel="cancel1"
      @confirm="save"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="item">
        <v-select
          :options="handleStatus"
          label="请选择审核状态"
          v-model="oStatus"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import {
  merchantStatus,
  merchantStatusMap,
  manageTypeops,
  manageTypeopsMap,
  businessTypeopsMap,
  businessTypeops,
  handleStatusMap,
  handleStatus,
} from "./map";
import {
  PointsMallGoodsDeleteById,
  handleMerchantUrl,
  merchantListUrl,
  judgeGoodUrl,
} from "./api";

export default {
  name: "merchantList",
  data() {
    return {
      oStatus: 1,
      isdialog1: false,
      handleStatus,
      id: "",
      isHave: "",
      form: {
        freezeReason: "",
      },
      isdialog: false,
      businessTypeops,
      manageTypeops,
      merchantStatus,
      searchParam: {
        communityId: null,
        title: null,
        status: 0,
        sortType: null,
        mobileNum: null,
        businessType: null,
        manageType: null,
        tenantId: this.$store.state.app.userInfo.tenantId,
      },
      tableUrl: merchantListUrl,
      headers: [
        {
          prop: "nickName",
          label: "商家昵称",
        },
        {
          prop: "mobileNum",
          label: "商家手机号",
        },
        {
          prop: "businessType",
          label: "商户类型",
          formatter: (row, prop) => businessTypeopsMap[row.businessType],
        },
        {
          prop: "title",
          label: "商家名称",
        },
        {
          prop: "manageType",
          label: "经营类型",
          formatter: (row, prop) => manageTypeopsMap[row.manageType],
        },
        {
          prop: "total",
          label: "交易笔数",
        },
        {
          prop: "consumedPonints",
          label: "已核销积分",
        },
        {
          prop: "noConsumedPonints",
          label: "待核销积分",
        },
        {
          prop: "insertTime",
          label: "申请时间",
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => merchantStatusMap[row.status],
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    toExport() {
      this.$axios
        .get(`/gateway/hc-serve/manageapi/pointsMallShop/exportShopInfos`, {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
    cancel() {},
    async confirm() {
      if (!this.form.freezeReason) {
        this.$message.info("请输入冻结原因");
        return;
      }
      let res = await this.$axios.post(
        `${handleMerchantUrl}?id=${this.id}&status=3&freezeReason=${this.form.freezeReason}`
      );
      if (res.code === 200) {
        this.$message.success("操作成功");
        this.isdialog = false;
        this.$refs.list.search();
      }
    },
    async judgeGood() {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(`${judgeGoodUrl}`, { params });
      return res;
    },
    toEdit(data) {
      this.$router.push({
        name: "merchantForm",
        query: { id: data.id },
      });
    },
    async save() {
      let res = await this.$axios.post(
        `${handleMerchantUrl}?id=${this.id}&status=${this.oStatus}`
      );
      if (res.code === 200) {
        this.$message.success("操作成功");
        this.isdialog1 = false;
        this.$refs.list.search();
      }
    },
    tohandle(data) {
      this.id = data.id;
      this.isdialog1 = true;
    },
    changeShelf(data) {
      this.$confirm("你确定要解冻？", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$axios.post(
            `${handleMerchantUrl}?id=${data.id}&status=5`
          );
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          }
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    async changeShelfSts(data, shelfSts) {
      this.id = data.id;
      let res = await this.judgeGood();
      if (res.code == 200) {
        this.isHave = res.data;
        this.isdialog = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.merchantList {
  box-sizing: border-box;
  height: 100%;
  .info {
    text-align: center;
    margin: 0 0 40px 0;
  }
  /deep/ .el-textarea {
    vertical-align: top;
  }
}
</style>
